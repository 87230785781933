import React from "react";
import { IoTimer, IoCheckmarkCircle, IoEye } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const RecruitmentTimesheets = (props) => (
  <Layout>
    <Seo
      title="Timesheets Management | Karmly Feature"
      description="Beautiful and fast timesheets & approvals. Make it easy for your contractors and clients and get full visibility of your contingent workforce process."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Timesheet Management"
      description="When you’re running a contingent workforce everything revolves around timesheets and approvals. So you’re going to want a system that makes those easy and fast for your contractors and your clients – and gives you full visibility of the process."
      image="InvoxyFeature_TimesheetManagement.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoTimer />
              <br />
              Online Timesheets
            </h3>
            <p>
              Timesheets have never looked so good! Flexible time entry options
              make it easy for everyone.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoCheckmarkCircle />
              <br />
              One-Click Approvals
            </h3>
            <p>
              Making approvals simple for your clients speeds up the process so
              you can invoice them faster.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoEye />
              <br />
              Complete Visibility
            </h3>
            <p>Quickly see the status of all timesheets and approvals.</p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_TimesheetManagement_TimeEntry.png"
              alt="Online Timesheets"
            />
          </Box>
          <Box size={50}>
            <h3>Beautiful, Intuitive Timesheets</h3>
            <p>
              Your contractors will love the simplicity of recording timesheets
              in Karmly. They can enter the full details – start, break and end
              times – or just the total duration for each day. It’s easy to copy
              or edit time entries, view the approval status and even copy the
              previous week’s timesheets in a single click. Keep it simple or
              let contractors record time for different types of work to track
              the time spent on different projects or jobs.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Fast Admin Time Entry</h3>
            <p>
              Still receiving paper timesheets from some clients? No problem.
              It’s quick and easy for your agency to enter time into Karmly as
              well. View a week at a time and enter the total number of hours or
              days worked by each contractor. Filter by client or placement and
              even enter bulk time entries for multiple contractors working the
              same shift.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_TimesheetManagement_BulkEntry.png"
              alt="Easy Bulk Time Entry"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_TimesheetManagement_EmailRequest.png"
              alt="Time Approval"
            />
          </Box>
          <Box size={50}>
            <h3>Easy Client Approvals</h3>
            <p>
              Make things simple for your clients by letting them approve
              timesheets in a single click from an email. Once contractors
              request approval for their timesheets your client will receive an
              email and they can see the full timesheet details, approve or
              decline and add notes – all without having to login. Your clients
              will love you for it!
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Full Audit Trail</h3>
            <p>
              Karmly keeps a record of all pending and approved requests. See
              when the request was sent by the contractor, when it was approved
              and who approved it. Attaching this detail to your invoices
              removes any questions or issues that could delay payment.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_TimesheetManagement_ApprovedRequest.png"
              alt="Historical Time Approvals"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_TimesheetManagement_AttachTimesheets.png"
              alt="Attach Timesheets to Invoices"
            />
          </Box>
          <Box size={50}>
            <h3>Attach timesheets to invoices</h3>
            <p>
              If you’ve got clients who aren’t ready to go online or send you
              timesheets from another source you can easily attach them to your
              invoices. However you manage the timesheet and approval process,
              attaching the details to the invoice results in fewer queries and
              faster payment.
            </p>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default RecruitmentTimesheets;
